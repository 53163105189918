body {
    margin: 0;
    padding: 0;
  }
  
  .mistake-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
  }
  
  .background-text {
    position: absolute;
    font-size: 33rem;
    opacity: 0.1;
    z-index: -1;
  }
  
  .mistake-content {
    text-align: center;
    max-width: 600px;
    width: 100%; 
    padding: 20px;
  }
  
  .mistake-title {
    font-size: 2.63rem;
    color: #16234d;
    margin: 20px 0;
  }
  
  .mistake-text {
    font-size: 1.2rem;
    color: #666;
    margin-top: 10px;
  }
  
  .mistake-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #16234d;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #37446e;
    }
  }
  
  /* Media Query for Mistake Page */
  @media only screen and (max-width: 1024px) {
    .mistake-container {
      padding: 10px;
    }
  
    .background-text {
      font-size: 55vw;
    }
  
    .mistake-title {
      font-size: 7vw;
    }
  
    .mistake-text {
      font-size: 2.5vw;
    }
  
    .mistake-link {
      font-size: 2vw;
      padding: 8px 16px;
    }
  }
  