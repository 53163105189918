/* Footer.css */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  margin: 0;
  text-decoration: none;
  padding: 0;
}

/* Main footer container */
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw; /* Responsive padding */
  font-family: 'Nunito', sans-serif;
}

.moreLinks{
  justify-content: center;
}

/* Logo styling */
.footerLogo {
  text-align: center;
  margin-bottom: 2vw; /* Responsive margin */
}

.footerLogo img {
  width: 140px;
  height: 35px;
}

/* Copyright notice */
.xdl-copyright p {
  font-size: 1.2vw; /* Responsive font size */
  color: #5B5B5B;
  font-weight: 400;
  margin-top: 4vw;
}

/* Links container styling */
.moreLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

/* Hover effect on links */
.moreLinks p:hover {
  color: #16234d;
}

/* Individual link sections */
.link-1,
.link-2,
.link-3,
.link-4 {
  flex: 1 1 40%; /* Responsive flex property */
  margin: 2vw; /* Responsive margin */
  max-width: 200px;
}

/* Heading styles within link sections */
.link-1 h4,
.link-2 h4,
.link-3 h4,
.link-4 h4 {
  color: #16234d;
  font-size: 1.8vw; /* Responsive font size */
}

/* Paragraph styles within link sections */
.link-1 p,
.link-2 p,
.link-3 p,
.link-4 p {
  margin: 1vw 0;
  font-size: 1.2vw; /* Responsive font size */
  color: black;
  font-weight: 500;
}

.link-1 a,
.link-2 a,
.link-3 a,
.link-4 a{
  margin: 1vw 0;
  font-size: 1.2vw; /* Responsive font size */
  color: black;
  font-weight: 500;
}

/* Hover effect on general links */
a:hover {
  text-decoration: underline;
  color: #16234d;
}

/* Active link styles */
a:active {
  color: #16234d;
}

/* Social icons section */
.socials {
  color: #16234d;
}

/* Heading styles for socials */
.socials h3 {
  font-size: 1.8vw; /* Responsive font size */
  margin-top: 2vw;
}

.socialIcons {
  display: flex;
  margin-top: 1vw;
}

/* Styling for individual social icons */
.socialIcons a {
  margin-right: 2vw;
}

.socialIcons img {
  max-height: 2vw; /* Responsive max-height */
}

/* Media Query for screens smaller than 1024px */
@media only screen and (max-width: 1024px) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .footerLogo img {
    width: 120px; /* Adjust the size as needed for smaller screens */
    margin-bottom: 10px;
  }

  .moreLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    text-align: center;
  }

  .link-1,
  .link-2,
  .link-3,
  .link-4 {
    width: 100%; /* Take full width on smaller screens */
    margin-bottom: 20px;
  }

  .link-1 h4,
  .link-2 h4,
  .link-3 h4,
  .link-4 h4 {
    font-size: 4.5vw; /* Responsive font size */
  }

  /* Paragraph styles within link sections */
  .link-1 p,
  .link-2 p,
  .link-3 p,
  .link-4 p {
    font-size: 3.7vw; 
    padding-bottom: 2vw;
  }


  .socials {
    margin-top: 20px;
  }

  .socials h3{
    font-size: 4.5vw;
  }

  .socialIcons {
    display: flex;
    gap: 10px;
    padding: 0;
    justify-content: center;
  }

  .socialIcons img{
    max-height: 5vw;
  }

  .xdl-copyright {
    display: flex;
    justify-content: center;
    font-size: 2vw;
    width: 100%;
  }

  .xdl-copyright p{
    font-size: 2.5vw;
  }
}