@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: "Nunito";  
}

.data-privacy{
  font-weight: 400;
  margin-top: 84px;
  flex-direction: row;
  padding: 0 35px 20px 35px;
  text-align: justify;
}

.data-privacy h1{
 font-weight: 700;
 font-size: 5vw;
 color: #16234d;
}


.data-privacy h2{
  font-weight: 700;
  font-size: 1.5vw;
 }

.data-privacy h3{
  font-weight: 600;
  font-size: 2vw;
  padding: 10px 0;
 }

 .data-privacy p{
  padding: 8px 0;
 }

 .data-privacy a{
  color: #16234d;
  text-decoration: underline;
 }

 @media only screen and (max-width: 1024px) {
    .data-privacy h1{
     font-size: 8vw;
    }

    .data-privacy h2{
      font-size: 3.5vw;
    }

    .data-privacy h3{
      font-size: 4.5vw;
     }
}
 