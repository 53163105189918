/* Google Font CDN */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
  *{
   font-family: Nunito;
   font-weight: 700;
   -webkit-tap-highlight-color: transparent;
   outline: none !important;
  }

  /* ::selection {
    background-color: transparent;
  } */
