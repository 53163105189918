@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: "Nunito";
}

/*  !! FAST · RELIABLE !!*/

.xdlLandingPage {
  margin-top: 84px;
  background-color: #E9EBEF;
  display: flex;
  flex-direction: row;
  padding: 0 0 20px 0;
}

.xdlIntro, .xdlVideo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 0 0 20px 20px;
  box-sizing: border-box;
  max-width: 50%;
}

.xdlIntro h1.fast {
  font-size: 4.35vw;
  /* border: 2px solid red; */
  padding: 35px 5px;
  width: auto;
  text-align: center;
  color: #16234d;
  font-weight: 650;
}

.xdlIntro h1.fast #middledot {
  color: red;
  font-size: 4vw;
}

.xdlIntro h3.desc {
  font-size: 1.5vw;
}

.xdlIntro .description {
  padding: 10px 0 60px 0;
  max-width: 565px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
}

button {
  font-weight: 700;
  font-size: 15px;
  background-color: #16234d;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0b163a;
}

.xdlVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  max-width: 50%;
}

.xdlVideo iframe {
  width: 100%;
  height: 100%;
  border-radius: 38px;
}

/*  ?? FAST · RELIABLE ??*/


/*  !! XDL-CLIENT !!*/
.xdlClient {
  background-color: #ffff;
  display: flex;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
}

.xdlClient .xdlClientImg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 0 0 20px 20px;
  box-sizing: border-box;
}

.xdlClientImg img {
  height: 35vw;
  width: 30vw;
  padding: 20px;
}

.xdlClientDesc {
  display: none;
  visibility: hidden;
}

.xdlBenefit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: left;
  padding: 0 10vw 0 0;
}

.xdlBenefit h2 {
  padding: 5vw 8vw 8vw 0;
  color: #16234d;
}

.xdlBenefit h3 {
  padding: 0 0 8vw 0;
  width: 70%;
  font-weight: 300;
}

.xdlBenefit a {
  display: flex;
  padding: 10vw 10vw 0 0;
  text-decoration: none;
  color: black;
}

.xdlBenefit a p {
  padding: 0 5px 15px 0;
  font-size: 27px;
  font-weight: 400;
}

.xdlBenefit .rightArrow {
  position: absolute;
  padding-top: 2.5px;
  padding-left: 10px;
  font-weight: 100;
}

/*  ?? XDL-CLIENT??*/

/* !! Grow your business with XOBO !! */

.grow {
  display: flex;
  flex-direction: row;
  background-color: #EFE6E6;
  padding: 30px 0;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.xdlBusiness,
.xdlDelivered {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  padding: 0 20px;
}

.xdlBusiness h1 {
  font-weight: 700;
  margin-bottom: 20px;
}

.xdlDelivered {
  justify-content: center;
  align-items: center;
}

.xdlDelivered img {
  width: 75%;
  height: 90%;
}

.grow .xdlBenefits-1,
.grow .xdlBenefits-2,
.grow .xdlBenefits-3,
.grow .xdlBenefits-4 {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.grow .xdlBenefits-1 p,
.grow .xdlBenefits-2 p,
.grow .xdlBenefits-3 p,
.grow .xdlBenefits-4 p {
  font-weight: 500;
}

.benefit-icon {
  margin-right: 16px;
}

.benefit-icon img {
  width: 60px;
}

#track {
  height: 80px;
  width: 50px;
}

.benefit-content {
  flex: 1;
  text-align: left;
}

.benefit-content h3 {
  font-size: 2vw;
  color: #16234d;
}



/* ?? Grow your business with XOBO ?? */

/* Media Queries for Responsiveness */
@media only screen and (max-width: 1024px) {
  .xdlLandingPage {
    flex-direction: column;
    min-height: auto;
  }

  .xdlIntro, .xdlVideo, .xdlClient {
    max-width: 80%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    align-items: center;
  }

  .xdlVideo iframe {
    width: 90vw;
    height: 60vw;
    padding: 2vw 0 0 0;
  }

  .xdlIntro h1.fast {
    font-size: 9vw;
    /* border: 1px solid red; */
    justify-content: center;
    padding: 40px 0;
    text-align: center; /* Make sure text alignment is centered */
    width: 400px;
    letter-spacing: 0.1px;
  }

  .xdlIntro h1.fast #middledot {
    font-size: 12vw;
  }

  .xdlIntro h3.desc {
    font-size: 5.3vw;
    text-align: center;
    word-spacing: -0.5vw;
  }

  .xdlIntro .description {
    max-width: 80%;
    text-align: center;
    text-align: justify;
  }

  button {
    font-size: 12px;
  }

  .xdlIntro, .xdlVideo, .xdlClient {
    order: 1; /* Reorder .xdlIntro, .xdlVideo, and .xdlClient to appear on top */
  }

  .xdlClient {
    flex-direction: column; /* Change to a column layout on smaller screens */
    padding: 10px;
  }

  .xdlClient .xdlClientImg {
    max-width: 100%; /* Make the image take full width */
    align-items: center; /* Center-align the content */
  }

  .xdlClientImg img {
    width: 100%; /* Make the image take full width */
    height: auto; /* Maintain aspect ratio */
    padding: 0; /* Remove left padding */
  }

  .xdlClientDesc {
    display: block;
    visibility: visible;
    width: 90vw;
    background-color: whitesmoke;
  }

  .xdlClientDesc h2{
    color: #16234d;    
    padding: 20px 0 40px 0;
  }

  .xdlClientDesc h3{
    color: black;
    font-weight: 200;
    padding-bottom: 40px;
  }

  .xdlClientDesc p{
    color: #16234d;
    text-decoration: none;
    padding-bottom: 10px;
    font-size: 4vw;
  }

  .xdlClient a{
    text-decoration: none;
  }

  .xdlClientImg img {
    height: 110vw;
    width: 90vw;
  }

  .xdlClient .rightArrow {
    position: absolute;
    padding-top: 4px;
    padding-left: 10px;
  }  

  .xdlBenefit {
    display: none;
  }

  .grow {
    flex-direction: column;
    padding: 10px;
  }

  .xdlBusiness,
  .xdlDelivered {
    max-width: 100%;
    padding: 0 10px;
  }

  .xdlBusiness h1 {
    font-size: 5.5vw;
  }

  .grow .xdlBenefits-1,
  .grow .xdlBenefits-2,
  .grow .xdlBenefits-3,
  .grow .xdlBenefits-4 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;
  }

  .benefit-icon {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .benefit-icon img {
    width: 60px;
  }

  .grow .xdlBenefits-1 img {
    order: -1;
    margin-bottom: 10px;
  }

  .grow .xdlBenefits-1 h3,
  .grow .xdlBenefits-2 h3,
  .grow .xdlBenefits-3 h3,
  .grow .xdlBenefits-4 h3 {
    font-size: 4.5vw;
    margin-top: 10px;
  }

  .grow .xdlBenefits-1 p,
  .grow .xdlBenefits-2 p,
  .grow .xdlBenefits-3 p,
  .grow .xdlBenefits-4 p {
    text-align: center;
    font-weight: 500;
    padding-top: 10px;
  }

  .xdlBusiness {
    text-align: center;
  }

  .xdlBusiness h1 {
    font-size: 4.5vw;
    margin-bottom: 20px;
  }

  .xdlBusiness .xdlBenefits-1,
  .xdlBusiness .xdlBenefits-2,
  .xdlBusiness .xdlBenefits-3,
  .xdlBusiness .xdlBenefits-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;
  }

  .xdlBusiness .benefit-icon {
    margin-bottom: 16px;
  }

  .xdlBusiness .benefit-icon img {
    width: 60px;
  }

  #track {
    height: 80px;
    width: 50px;
  }

  .xdlBusiness .benefit-content {
    flex: 1;
    text-align: center;
  }

  .xdlDelivered img {
    width: 90vw;
    height: 110vw;
  }
}
