/* contact.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: "Nunito";
}

.contact {
  padding: 84px 10px 0 10px;
}

.contactDetails {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
}

.contactDetails div a {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex-grow: 1;
  text-align: center;
}

.contactDetails img {
  max-width: 100%;
  height: 50px;
  padding-right: 15px;
}

.contactDetails a {
  text-decoration: none;
  color: #16234d;
}

.contact-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1.3px solid #16234d;
  border-radius: 10px;
}

/* Updated CSS for the select element */
.contact-container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #16234d;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  color: #16234d;
  font-size: 15px;
  height: 40px; /* Adjust the height as needed */
}

.contact-container h3 {
  text-align: center;
  color: #16234d;
  font-size: 25px;
}

.contact-container p {
  text-align: center;
  padding: 0 20px 10px 20px;
}

.contact-container form {
  display: grid;
  gap: 15px;
}

.contact-container input,
.contact-container textarea,
.phone-input input {
  text-transform: capitalize;
  width: 100%;
  padding: 10px;
  border: 1px solid #16234d;
  border-radius: 5px;
  margin-bottom: 2px;
  box-sizing: border-box;
}

.phone-input {
  width: 100%;
  border: 1px solid #16234d;
  border-radius: 5px;
  margin-bottom: 5px;
}

.contact-container input[type="submit"],
.contact-container button {
  background-color: #16234d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Media query for screens with a maximum width of 1024px */
@media only screen and (max-width: 1024px) {
  .contactDetails {
    flex-direction: column;
    text-align: center;
  }

  .contactDetails div {
    margin-bottom: 10px;
  }

  .contact-container {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .contact-container form {
    grid-template-columns: 1fr;
  }
}
