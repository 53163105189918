@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: "Nunito";
}

body.no-scroll {
  overflow: hidden;
  
}

header {
  /* border: 2px solid blue; */
  padding: 5px 0 5px 10px;
  height: auto;
  display: flex;
  width: 100%;
  background-color: #ffff;
  position: fixed;
  top: 0;
  z-index: 100;
  justify-content: space-between; /* To push logo to the left and content to the right */
}

.logo img {
  /* border: 2px solid red; */
  width: 160px;
  height: 45px;
  margin-top: 0.6vw;
  visibility: visible; /* Show the logo initially */
  opacity: 1; /* Show the logo initially */
  transition: visibility 0.5s, opacity 0.5s; /* Smooth transition effect */
}

nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0; /* Remove padding to center-align both button and links */
}

nav ul li {
  list-style: none;
  padding: 0 40px 0 0;
}

nav a {
  font-size: 16px;
  color: #16234d;  
}

nav a:hover {
  color: #0b163a;  
  text-decoration: none;
}

.orderBtn {
  font-size: 15px;
  background-color: #16234d;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* margin-left: 0; Remove this line to center-align the button */
}

button:hover{
  background-color: #0b163a;
}

header .nav-btn {
  padding: 0;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  background: transparent;
  border: none;
  outline: none;
}

@media only screen and (max-width: 1024px) {

  header{
    visibility: visible;
    opacity: 20;
  }

  .logo img {
    visibility: visible;
    opacity: 1;
  }

  header .nav-btn {
    visibility: visible;
    color: #16234d;
    padding: 0 30px 0 0;
    opacity: 1;
    font-size: 1.5rem;
  }


  header nav {
    position: fixed;
    top: 6vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    /* justify-content: center;  */
    padding: 5vw 0 0 0;
    gap: 2rem;
    background-color: #E9EBEF;
    transition: 1.5s;
  }

  header .hidden {
    transform: translateY(-100%);
    visibility: hidden;
    opacity: 0;
  }
  
  /* Show the navigation bar when the hamburger icon is clicked */
  header .nav-btn:focus ~ nav.responsive_nav {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
    
  .orderBtn {
    padding: 15px 20vw;
    font-size: 16px;
  }

  header .responsive_nav {
    transform: translateY(1vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }

  

  /* Unhide the logo when the navigation bar is open */
  header .nav-btn:focus ~ .logo img {
    visibility: visible;
    opacity: 1;
  }
}
